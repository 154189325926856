/*---------------------------------------------

===============================================   
STYLESHEET INDEXING
|
|___ Default Styles
|	|___ Variables
|	|___ Reset Styels
|	|___ Typography
|	|___ Forms Styles
|	|___ Helper Classes 
|
|___ Elements Styles
|	|___ Back To Top
|	|___ Breadcrumb
|	|___ Button
|	|___ Pagination 
|	|___ Section Heading
|	|___ Slick 
|
|___Template Styles
|	|___ About Us
|	|___ Banner
|	|___ Brand
|	|___ Call To Action
|	|___ Case Study
|	|___ Contact
|	|___ Counter Up
|	|___ FAQ
|	|___ Pricing
|	|___ Process
|	|___ Project
|	|___ Service
|	|___ Team
|	|___ Testimonial
|	|___  Why Choose
|	|___  404 Error
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|___Dark Style
|
|___Spacing
|
|___RTL
|
|___ END STYLESHEET INDEXING

--------------------------------------------*/

/*=======================================================================
1. Default Styles
=========================================================================*/
@import "default/variable";
@import "default/reset";
@import "default/typography";
@import "default/form";
@import "default/classes";

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import "elements/animate";
@import "elements/back-top";
@import "elements/breadcrumb";
@import "elements/button";
@import "elements/pagination";
@import "elements/section-heading";
@import "elements/slick";

/*=======================================================================
3. Template Styles
=========================================================================*/
@import "template/about";
@import "template/banner";
@import "template/brand";
@import "template/call-to-action";
@import "template/case-study";
@import "template/contact";
@import "template/counterup";
@import "template/faq";
@import "template/pricing";
@import "template/process";
@import "template/project";
@import "template/service";
@import "template/team";
@import "template/testimonial";
@import "template/why-choose";
@import "template/common-pages";
@import "template/splash.scss";
@import "template/onepage";

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
@import "header/header";
@import "blog/blog";
@import "blog/widget";
@import "footer/footer";

/*=======================================================================
5. Dark Style 
=========================================================================*/
@import "dark-style/dark-version";

/*=======================================================================
6. Spacing 
=========================================================================*/
@import "default/spacing";

/*=======================================================================
7. RTL 
=========================================================================*/
// @import "rtl/rtl";

.StaffAugmentation ul {
    padding-top: 10px;
    padding-left: 32px;
    // li{
    //     font-size: 18px;
    //     line-height: 25px;
    // }
}
.augmentation-card {
    background: #ffffff;
    box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}
.augmentation-card p.mt-2 {
    min-height: 96px;
}
.augmentation-card h6 {
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    margin: 0;
}
.augmentation-card .explore {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #2b569c;
}
.augmentation-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5fc300;
    position: relative;
    display: inline-block;
}
.augmentation-title::before {
    content: "";
    border: 1px solid #5fc300;
    position: absolute;
    width: 100%;
    top: 23px;
}

.augmentation-title.num2 {
    color: #c39800;
}
.augmentation-title.num2::before {
    border: 1px solid #c39800;
}
.augmentation-title.num3 {
    color: #4628a4;
}
.augmentation-title.num3::before {
    border: 1px solid #4628a4;
}
.augmentation-title.num4 {
    color: #1a7bff;
}
.augmentation-title.num4::before {
    border: 1px solid #1a7bff;
}

// swiper js

.swiper {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    display: inline-block;
}

.swiper-slide h4 {
    font-style: normal;
    font-weight: 700;
    color: var(--color-primary);
    display: inline-block;
    padding-bottom: 8px;
    // text-decoration: underline;
    border-bottom: 1px solid var(--color-primary);
}

.swiper-wrapper {
    display: inline-block;
}

.fileUpload {
    padding: 20px;
    text-align: center;
    background: rgb(255 255 255 / 25%);
    border: 2px dashed rgb(36 144 239 / 25%);
    border-radius: 4px;
    position: relative;
}
.fileUpload .fileUpload-mes {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #bbbbbb;
    margin: 0 5px;
}
.fileUpload .fileUpload-mes span {
    color: var(--color-primary);
}
.fileUpload .fileUpload-inp {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
}

.onepagefixed {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 5;
}

// .axil-mainmenu.axil-sticky {
//     height: 90px !important;
// }

.kushal-class {
    &.active {
        background-color: var(--color-link);
    }
    color: var(--color-primary) !important;
    border-bottom: 2px solid var(--color-primary) !important;
    &:hover::after {
        background-color: transparent;
    }
}

.vision-mission-wrapper {
    display: flex;
    height: 100%;
    padding-top: 40px;
    flex-direction: column;
}
.vision-mission-wrapper .vm-widget {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.vision-mission-wrapper .vm-widget .vm-icon {
    width: 60px;
}
.vision-mission-wrapper .vm-widget .vm-content {
    margin-left: 20px;
    width: calc(100% - 80px);
}

.career-box {
    color: #6a6a8e;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    font-size: 17px;
    visibility: visible;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    outline: 0;
    vertical-align: baseline;
    background: #fff;
    background-size: 200%;
    background-position: top right;
    background-repeat: no-repeat;
    text-align: center;
    padding: 50px 20px;
    margin: 0;
    position: relative;
    border: 1px solid #d4f0ff;
    border-radius: 8px;
    box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
        0 25px 40px -30px rgba(0, 0, 0, 0.2);
    transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
    overflow: hidden;
    // min-height: 360px;
    cursor: pointer;
    margin-bottom: 60px;
}
.career-box::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0%;
    bottom: 0;
    height: 4px;
    background: -webkit-linear-gradient(-45deg, #fc00c7 0, #1c4efd 54%, #00aded 100%);
    background: linear-gradient(to right, #253e75 0, #2196f3 100%);
    -webkit-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
    -o-transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
    transition: 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.career-box:hover:after {
    width: 100%;
    overflow: hidden;
}
.career-box:hover {
    transform: translateY(-7.5px);
    box-shadow: 0 15px 39px -11px #46638e45;
}
.career-box .career-box-img {
    width: 80px;
    margin: 0 auto 30px;
    height: 80px;
}
.career-box .contact-img {
    height: 220px;
}
.career-box h4 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
}
.career-box p {
    margin-bottom: 15px;
}
.career-box .view-more {
    margin: 30px 0 0 0;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.rate-row {
    margin: 75px 0;
}

.rate-row .review-h4 h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 56px;
    letter-spacing: 0.01em;
    color: #192734;
    text-align: left;
    margin: 0 0 30px 0;
}

.rate-row .review {
    height: 75px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 45px 0;
}

.review-border {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
@media (max-width:767px){
    .review-border {
        border: none;
    }
}

// demo button

.demo_btn{
    border: 2px solid white;
    top: 115px;
    z-index: 99;
    left: 50px;
    background-color: #0069ff;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 8px 20px;
    box-shadow: 0 2px 5px 0 #0069ff;
    color: white;
    border-inline: 2px solid white;
    font-weight: bold;
    font-size: 14px;

    &:hover{
         background-color: #207af7;
         color: white;
    }

    @media (max-width: 768px) {
        left: 40px;
    }
    @media (max-width: 767px){
        top: 16%;
        left: 130px;
    }
    @media (max-width: 575px) {
        left: 5%;
        top: 16%;
    }
}

.button-wiggle , .calendly-badge-widget{
  animation: wiggle 4s 1s infinite;
}

@keyframes wiggle {
	5%,
	50% {
		transform: scale(1);
	}
	10% {
		transform: scale(0.9);
	}
	15% {
		transform: scale(1.15);
	}
	20% {
		transform: scale(1.15) rotate(-5deg);
	}
	25% {
		transform: scale(1.15) rotate(5deg);
	}
	30% {
		transform: scale(1.15) rotate(-3deg);
	}
	35% {
		transform: scale(1.15) rotate(2deg);
	}
	40% {
		transform: scale(1.15) rotate(0);
	}
}

.button-pulse {
  animation: pulse 1.5s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 blueviolet;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0); 
  }
}

.button-float {
  animation: float 1.5s linear alternate infinite;
}

@keyframes float {
	50% {
    transform: translateY(-px);
  }
  100% {
    transform: translateY(-18px);
  }
}


/* Popup overlay styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  /* Popup container styles */
  .popup-container {
    background: #fff;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
  }
  
  /* Popup header styles */
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .popup-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .btn-close {
    border: none;
    background: transparent;
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
  }
  
  /* Popup subtitle */
  .popup-subtitle {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Form input styles */
  .popup-form .form-control {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  /* Button styles */
  .popup-form button {
    background: #5cb3e3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: none !important;
  }
  
  .popup-form button:hover {
    background: #4799c8;
  }



